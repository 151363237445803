/**
 * @desc 人脸图库
 */

import React from 'react'
import Wrapper from './components/wrapper'

export default class faceLibraryIndex extends React.Component {
  render(){
    return <Wrapper />
  }
}
