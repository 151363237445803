(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("antd"), require("ReactRouterDOM"), require("_"));
	else if(typeof define === 'function' && define.amd)
		define("faceLibrary", ["React", "mobx", "antd", "ReactRouterDOM", "_"], factory);
	else if(typeof exports === 'object')
		exports["faceLibrary"] = factory(require("React"), require("mobx"), require("antd"), require("ReactRouterDOM"), require("_"));
	else
		root["faceLibrary"] = factory(root["React"], root["mobx"], root["antd"], root["ReactRouterDOM"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 